import './App.css';
import { useState,React } from 'react';
import { FaLinkedin, FaGithub, FaGlobe,FaEnvelope } from 'react-icons/fa';
import { AiOutlineFilePdf } from 'react-icons/ai';

function Main() {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
      // Update the position state with cursor coordinates
      setPosition({ x: e.clientX, y: e.clientY });
    };
  return (
    <div className="App">
      <header className="App-header">
        <div className="container" onMouseMove={handleMouseMove}>
          <div
            className="circle"
            style={{
              top: `${position.y}px`,
              left: `${position.x}px`,
            }}
          ></div>

          {/* Name */}
          <h1 className="name">Shivam Raj Dubey</h1>

          {/* Profile Image */}
          <div className="profile-image-container">
            <img
              src="/srd.jpg" // Replace with your image URL
              alt="Shivam Raj Dubey"
              className="profile-image"
            />
          </div>

          {/* About Me */}
          <p className="about">
            Hello! I’m Shivam Raj Dubey, a passionate <span className='neon-green'> software developer</span> with a keen interest in <span className='neon-green'>full-stack development</span> and<span className='neon-green'> AI </span>.
          </p>

          {/* Social Links */}
          <div className="social-icons-container">
            <div className="social-icons">
              <a href="https://www.linkedin.com/in/shivamrajdubey" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="icon" />
              </a>
              <a href="https://github.com/shivam-dubey-byte" target="_blank" rel="noopener noreferrer">
                <FaGithub className="icon" />
              </a>
              <a href="mailto:dubeyshivamraj@gmail.com" className="icon">
                <FaEnvelope size={30} />
              </a>
              <a href="/resume" target="_blank" rel="noopener noreferrer">
                <AiOutlineFilePdf className="icon" />
              </a>
              <a href="https://quantumsoftdev.in" target="_blank" rel="noopener noreferrer">
                <FaGlobe className="icon" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Main
