import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Main';
import Resume from './Resume';
function App() {

  return (
    <Router>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/resume" element={<Resume />} />
    </Routes>
  </Router>

  );
}

export default App;
