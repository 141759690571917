import React from 'react'

const Resume = () => {
    const pdfUrl = "/SRD.pdf"; // Replace with your PDF file path
  return (
    <div >
      <iframe
        src={pdfUrl}
        style={{ width: "100%", height: "100vh", border: "none" }}
        title="PDF Viewer"
      />
    </div>
  )
}

export default Resume
